import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import { connect } from "react-redux";

import * as actionsBankAccount from '../../store/BankAccount/actions';
import * as actionsCurrency from '../../store/Currency/actions';
import * as actionsScheduledTask from '../../store/ScheduledTask/actions';
import * as actionsSupplier from '../../store/Supplier/actions';
import * as actionsSupplierBankAccount from '../../store/SupplierBankAccount/actions';
import Backdrop from '../Common/Backdrop';
import { ChargeBearer } from "definitions/enums/ChargeBearer";
import * as cronHelper from '../../helpers/cronHelper';
import * as editFormControls from '../../helpers/editFormControls';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';
import { PaymentUrgency } from "definitions/enums/PaymentUrgency";
import * as ScheduledTaskType from '../../definitions/enums/ScheduledTaskType'
import ScheduleSelector from 'components/Common/ScheduleSelector';

import classes from './Pages.module.css';

const MinWidthColVerticallyCentered = (props) => {
    const divStyle = props.width
        ? { height: "100%", display: "flex", alignItems: "center", width: props.width }
        : { height: "100%", display: "flex", alignItems: "center" };
    return (
        <Col xs="auto">
            <div style={divStyle}>
                {props.children}
                &nbsp;&nbsp;
            </div>
        </Col>
    )
}

const fieldDefaultValues = {
    id: 0,
    cronExpression: "* 0 8 ? * 1-5",
    name: "",
    forBudgetingOnly: false,
    bankAccountId: 0,
    allowCurrentDay: false,
    type: "STATEMENT_REQUEST",
    urgency: "NURG",
    chargeBearer: "DEBT",
    paymentDetails: "Scheduled payment",
    supplierId: 0,
    supplierBankAccountId: 0,
    amountToPay: 0,
    currencyId: 0,

    deleteEmptyStatements: false,
    deleteAllStatements: false,
    emptyStatementsToDeleteMinAgeInDays: 1,
    allStatementsToDeleteMinAgeInDays: 3,
    createAllocationReportUponStatementDeletion: true,
    deleteExternalDataSyncRequests: false,
    externalDataSyncRequestsToDeleteMinAgeInDays: 30,
}

class EditScheduledTask extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...fieldDefaultValues,

            changed: false
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        if (thisObjectName === "supplierId") {
            this.setSupplierBankAccountIdBySelectedSupplier(thisValue);
        }
        this.setState({
            [thisObjectName]: thisValue
        });
    }

    setStateFromScheduledTask() {
        if (this.props.scheduledTask && this.props.scheduledTask.id) {
            this.setState({
                id: this.props.scheduledTask.id,
                cronExpression: cronHelper.backendToFrontend(this.props.scheduledTask.cronExpression),
                name: this.props.scheduledTask.name,
                forBudgetingOnly: this.props.scheduledTask.forBudgetingOnly,
                bankAccountId: this.props.scheduledTask.bankAccountId,
                allowCurrentDay: this.props.scheduledTask.allowCurrentDay,
                type: this.props.scheduledTask.type,
                urgency: this.props.scheduledTask.urgency,
                chargeBearer: this.props.scheduledTask.chargeBearer,
                paymentDetails: this.props.scheduledTask.paymentDetails,
                supplierId: this.props.scheduledTask.supplierId,
                supplierBankAccountId: this.props.scheduledTask.supplierBankAccountId,
                amountToPay: this.props.scheduledTask.amountToPay,
                currencyId: this.props.scheduledTask.currencyId,

                deleteEmptyStatements: this.props.scheduledTask.deleteEmptyStatements,
                deleteAllStatements: this.props.scheduledTask.deleteAllStatements,
                emptyStatementsToDeleteMinAgeInDays: this.props.scheduledTask.emptyStatementsToDeleteMinAgeInDays,
                allStatementsToDeleteMinAgeInDays: this.props.scheduledTask.allStatementsToDeleteMinAgeInDays,
                createAllocationReportUponStatementDeletion: this.props.scheduledTask.createAllocationReportUponStatementDeletion,
                deleteExternalDataSyncRequests: this.props.scheduledTask.deleteExternalDataSyncRequests,
                externalDataSyncRequestsToDeleteMinAgeInDays: this.props.scheduledTask.externalDataSyncRequestsToDeleteMinAgeInDays,
            });
        } else {
            this.setState({
                ...fieldDefaultValues
            });
        }
    }

    componentDidMount() {
        this.setStateFromScheduledTask();
        if (!this.props.bankAccounts || !this.props.bankAccounts.length) {
            this.props.onGetBankAccounts();
        } else {
            if (!this.props.scheduledTask || !this.props.scheduledTask.bankAccountId) {
                this.setState({
                    bankAccountId: this.props.bankAccounts[0].id
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.scheduledTask !== this.props.scheduledTask) {
            this.setStateFromScheduledTask();
        }
        if (!this.props.bankAccounts || !this.props.bankAccounts.length) {
            this.props.onGetBankAccounts();
        }
        if (prevProps.bankAccounts !== this.props.bankAccounts && this.props.bankAccounts && this.props.bankAccounts.length && (!this.props.scheduledTask || !this.props.scheduledTask.bankAccountId)) {
            this.setState({
                bankAccountId: this.props.bankAccounts[0].id
            });
        }
        if (prevProps.currencies !== this.props.currencies && this.props.currencies && this.props.currencies.length && (!this.props.scheduledTask || !this.props.scheduledTask.currencyId)) {
            this.setState({
                currencyId: this.props.currencies[0].id
            });
        }
        if (prevProps.suppliers !== this.props.suppliers && this.props.suppliers && this.props.suppliers.length && (!this.props.scheduledTask || !this.props.scheduledTask.supplierId)) {
            this.setSupplierBankAccountIdBySelectedSupplier(this.props.suppliers[0].id)
            this.setState({
                supplierId: this.props.suppliers[0].id
            });
        }
        if (prevState.type !== this.state.type && this.state.type === "PAYMENT") {
            this.props.onGetBankAccounts();
            this.props.onGetCurrencies();
            this.props.onGetSupplierBankAccounts();
            this.props.onGetSuppliers();
        }
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        let newOrUpdatedScheduledTask = {
            cronExpression: cronHelper.frontendToBackend(this.state.cronExpression),
            name: this.state.name,
            forBudgetingOnly: this.state.forBudgetingOnly,
            bankAccountId: this.state.bankAccountId,
            allowCurrentDay: this.state.allowCurrentDay,
            type: this.state.type,
            urgency: this.state.urgency,
            chargeBearer: this.state.chargeBearer,
            paymentDetails: this.state.paymentDetails,
            supplierId: this.state.supplierId,
            supplierBankAccountId: this.state.supplierBankAccountId,
            amountToPay: this.state.amountToPay,
            currencyId: this.state.currencyId,

            deleteEmptyStatements: this.state.deleteEmptyStatements,
            deleteAllStatements: this.state.deleteAllStatements,
            emptyStatementsToDeleteMinAgeInDays: this.state.emptyStatementsToDeleteMinAgeInDays,
            allStatementsToDeleteMinAgeInDays: this.state.allStatementsToDeleteMinAgeInDays,
            createAllocationReportUponStatementDeletion: this.state.createAllocationReportUponStatementDeletion,
            deleteExternalDataSyncRequests: this.state.deleteExternalDataSyncRequests,
            externalDataSyncRequestsToDeleteMinAgeInDays: this.state.externalDataSyncRequestsToDeleteMinAgeInDays,
        };
        if (this.state.id) {
            newOrUpdatedScheduledTask = {
                id: this.state.id,
                ...newOrUpdatedScheduledTask
            };
        }
        this.props.onSaveScheduledTask(newOrUpdatedScheduledTask);
        this.setState({
            changed: false
        });
        this.props.onClose();
    }

    closeForm = () => {
        this.props.onClose();
    }

    handleApplySchedule = (cronExpression) => {
        this.setState({
            cronExpression: cronExpression
        });
    };

    filterSupplierBankAccountsBySelectedSupplier = (supplierId) => {
        return this.props.supplierBankAccounts
            ? this.props.supplierBankAccounts.filter(
                supplierBankAccountRow => supplierBankAccountRow.supplierId == supplierId)
            : [];
    }

    setSupplierBankAccountIdBySelectedSupplier = (supplierId) => {
        const supplierBankAccountsFiltered = this.filterSupplierBankAccountsBySelectedSupplier(supplierId);
        this.setState({
            supplierBankAccountId: supplierBankAccountsFiltered.length ? supplierBankAccountsFiltered[0].id : null
        });
    }

    render() {

        const typeOptions = inputSelectUtils.generateOptionsFromData(
            ScheduledTaskType.ScheduledTaskType,
            row => row.description
        );

        const supplierBankAccountsFiltered = this.filterSupplierBankAccountsBySelectedSupplier(this.state.supplierId);

        const bankAccountOptions = inputSelectUtils.generateOptionsFromData(this.props.bankAccounts, row => row.accountNo + " " + row.currencyCode);
        const chargeBearerOptions = inputSelectUtils.generateOptionsFromData(ChargeBearer, chargeBearerRow => chargeBearerRow.description);
        const currencyOptions = inputSelectUtils.generateOptionsFromData(this.props.currencies, currencyRow => (currencyRow.code + (currencyRow.name ? " (" + currencyRow.name + ")" : "")));
        const supplierBankAccountOptions = inputSelectUtils.generateOptionsFromData(supplierBankAccountsFiltered, supplierBankAccountRow => supplierBankAccountRow.accountNo);
        const supplierOptions = inputSelectUtils.generateOptionsFromData(this.props.suppliers, supplierRow => supplierRow.name);
        const urgencyOptions = inputSelectUtils.generateOptionsFromData(PaymentUrgency, row => row.description);

        const currencyCode = (this.props.currencies && this.state.currencyId) ? this.props.currencies.filter(currency => currency.id == this.state.currencyId)[0].code : "";

        let scheduledTaskControls;
        switch (this.state.type) {
            case "DATA_CLEANUP":
                scheduledTaskControls =
                    <React.Fragment>
                        <FormGroup className="mb-4" row>
                            <Col lg={editFormControls.labelColSize}>
                                <Label>
                                    Delete bank statements
                                </Label>
                            </Col>
                            <Col>
                                <Row>
                                    <div style={{ display: "flex", justifyContent: "flex-start", paddingBottom: "0.5rem" }}>
                                        <MinWidthColVerticallyCentered>
                                            {editFormControls.checkboxControlWithoutLabel("deleteEmptyStatements", this.onChange, this.state.deleteEmptyStatements)}
                                        </MinWidthColVerticallyCentered>
                                        <MinWidthColVerticallyCentered>
                                            Empty statements at least
                                        </MinWidthColVerticallyCentered>
                                        <MinWidthColVerticallyCentered width="5rem">
                                            {editFormControls.numberControlWithMinMaxWithoutLabel("emptyStatementsToDeleteMinAgeInDays", "", this.onChange, this.state.emptyStatementsToDeleteMinAgeInDays, 1, 0, 10000, !this.state.deleteEmptyStatements)}
                                        </MinWidthColVerticallyCentered>
                                        <MinWidthColVerticallyCentered>
                                            days old
                                        </MinWidthColVerticallyCentered>
                                    </div>
                                </Row>
                                <Row>
                                    <div style={{ display: "flex", justifyContent: "flex-start", paddingBottom: "1rem" }}>
                                        <MinWidthColVerticallyCentered>
                                            {editFormControls.checkboxControlWithoutLabel("deleteAllStatements", this.onChange, this.state.deleteAllStatements)}
                                        </MinWidthColVerticallyCentered>
                                        <MinWidthColVerticallyCentered>
                                            All statements at least
                                        </MinWidthColVerticallyCentered>
                                        <MinWidthColVerticallyCentered width="5rem">
                                            {editFormControls.numberControlWithMinMaxWithoutLabel("allStatementsToDeleteMinAgeInDays", "", this.onChange, this.state.allStatementsToDeleteMinAgeInDays, 1, 0, 10000, !this.state.deleteAllStatements)}
                                        </MinWidthColVerticallyCentered>
                                        <MinWidthColVerticallyCentered>
                                            days old
                                        </MinWidthColVerticallyCentered>
                                    </div>
                                </Row>
                                <Row>
                                    <div style={{ display: "flex", justifyContent: "flex-start", paddingBottom: "0.5rem" }}>
                                        <MinWidthColVerticallyCentered />
                                        <MinWidthColVerticallyCentered />
                                        <MinWidthColVerticallyCentered />
                                        <MinWidthColVerticallyCentered />
                                        <MinWidthColVerticallyCentered>
                                            {editFormControls.checkboxControlWithoutLabel("createAllocationReportUponStatementDeletion", this.onChange, this.state.createAllocationReportUponStatementDeletion, !this.state.deleteAllStatements)}
                                        </MinWidthColVerticallyCentered>
                                        <MinWidthColVerticallyCentered>
                                            Save bank statement as a report upon deletion
                                        </MinWidthColVerticallyCentered>
                                    </div>
                                </Row>

                            </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                            <Col lg={editFormControls.labelColSize}>
                                <Label>
                                    Delete external data sync history
                                </Label>
                            </Col>
                            <Col>
                                <Row>
                                    <div style={{ display: "flex", justifyContent: "flex-start", paddingBottom: "1rem" }}>
                                        <MinWidthColVerticallyCentered>
                                            {editFormControls.checkboxControlWithoutLabel("deleteExternalDataSyncRequests", this.onChange, this.state.deleteExternalDataSyncRequests)}
                                        </MinWidthColVerticallyCentered>
                                        <MinWidthColVerticallyCentered>
                                            Sync requests at least
                                        </MinWidthColVerticallyCentered>
                                        <MinWidthColVerticallyCentered width="5rem">
                                            {editFormControls.numberControlWithMinMaxWithoutLabel("externalDataSyncRequestsToDeleteMinAgeInDays", "", this.onChange, this.state.externalDataSyncRequestsToDeleteMinAgeInDays, 1, 0, 10000, !this.state.deleteExternalDataSyncRequests)}
                                        </MinWidthColVerticallyCentered>
                                        <MinWidthColVerticallyCentered>
                                            days old
                                        </MinWidthColVerticallyCentered>
                                    </div>
                                </Row>
                            </Col>
                        </FormGroup>
                    </React.Fragment>
                break;
            case "PAYMENT":
                scheduledTaskControls =
                    <React.Fragment>
                        {editFormControls.checkboxControl("forBudgetingOnly", "For budgeting only", this.onChange, this.state.forBudgetingOnly)}
                        {!this.state.forBudgetingOnly && editFormControls.selectControl("supplierId", "Supplier", this.onChange, this.state.supplierId, supplierOptions)}
                        {!this.state.forBudgetingOnly && editFormControls.selectControl("supplierBankAccountId", "Supplier bank account", this.onChange, this.state.supplierBankAccountId, supplierBankAccountOptions, false, !supplierBankAccountsFiltered.length)}
                        {!this.state.forBudgetingOnly && editFormControls.selectControl("bankAccountId", "Account to pay from", this.onChange, this.state.bankAccountId, bankAccountOptions)}
                        {editFormControls.selectControl("currencyId", "Currency", this.onChange, this.state.currencyId, currencyOptions)}
                        {!this.state.forBudgetingOnly && editFormControls.selectControl("chargeBearer", "Charge bearer", this.onChange, this.state.chargeBearer, chargeBearerOptions)}
                        {!this.state.forBudgetingOnly && editFormControls.selectControl("urgency", "Urgency", this.onChange, this.state.urgency, urgencyOptions)}
                        {!this.state.forBudgetingOnly && editFormControls.textAreaControl("paymentDetails", "Payment details", this.onChange, this.state.paymentDetails)}
                        {editFormControls.numberControl("amountToPay", "Amount to pay, " + currencyCode, this.onChange, this.state.amountToPay, 0.01, "", false, !this.state.amountToPay)}
                    </React.Fragment>
                break;
            case "STATEMENT_REQUEST":
                scheduledTaskControls =
                    <React.Fragment>
                        {editFormControls.selectControl("bankAccountId", "Bank account", this.onChange, this.state.bankAccountId, bankAccountOptions)}
                        {editFormControls.checkboxControl("allowCurrentDay", "Allow current day requests", this.onChange, this.state.allowCurrentDay)}
                    </React.Fragment>
                break;
        }

        const editForm =
            <form
                className="outer-repeater"
                onSubmit={this.onSubmit}
            >
                {editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
                {editFormControls.hiddenValueControl("cronExpression", this.onChange, this.state.cronExpression)}

                {editFormControls.textControl("name", "Task name", this.onChange, this.state.name)}
                {editFormControls.selectControl("type", "Task type", this.onChange, this.state.type, typeOptions, this.state.id)}
                {/* {editFormControls.staticTextControl("cronExpression", "Cron expression", this.state.cronExpression)} */}

                <FormGroup className="mb-4" row>
                    <Label
                        className={editFormControls.labelClass}
                    >
                        Schedule
                    </Label>
                    <Col lg={editFormControls.valueColSize}>
                        <ScheduleSelector
                            cronExpression={this.state.cronExpression}
                            toggle={this.toggleScheduleModal}
                            onApply={this.handleApplySchedule}
                        />
                    </Col>
                </FormGroup>

                {scheduledTaskControls}

                <Row>
                    <Col></Col>
                    <Col>
                        {editFormControls.saveButton(this.props.saving, this.state.id)}
                    </Col>
                </Row>
            </form>

        return (

            <div>
                <Backdrop
                    show
                    onClick={this.closeForm}
                >
                </Backdrop>
                <div className={classes.EditScheduledTaskContainer}>
                    <Card className={classes.PageCard} style={{ height: "100%" }}>  {/* Setting height of PageCard in css is ignored */}
                        <CardTitle>
                            <div className={classes.CardTitleDiv}>
                                <div className={classes.CardTitleSubDiv}>
                                    {this.props.scheduledTask ? "Edit" : "Create"} scheduled task
                                </div>
                                <div className={classes.CloseButtonDiv}>
                                    <p className={classes.CloseButtonDivP}>
                                        <i
                                            className="bx bx-x"
                                            onClick={this.closeForm}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </p>
                                </div>
                            </div>
                            <br />
                        </CardTitle>
                        <CardBody className={classes.EditScheduledTaskCardBody}>

                            {this.props.loadingBankAccounts
                                ? editFormControls.bigSpinner()
                                : editForm}

                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ bankAccount, currency, scheduledTask, supplier, supplierBankAccount }) => ({
    bankAccounts: bankAccount.bankAccounts,
    currencies: currency.currencies,
    loadingBankAccounts: bankAccount.loading,
    loadingCurrencies: currency.loading,
    loadingSupplierBankAccounts: supplierBankAccount.loading,
    loadingSuppliers: supplier.loading,
    saving: scheduledTask.saving,
    supplierBankAccounts: supplierBankAccount.supplierBankAccounts,
    suppliers: supplier.suppliers,
});

const mapDispatchToProps = dispatch => ({
    onGetBankAccounts: () => dispatch(actionsBankAccount.bankAccountGetAll()),
    onGetCurrencies: () => dispatch(actionsCurrency.currencyGetAll()),
    onGetSupplierBankAccounts: () => dispatch(actionsSupplierBankAccount.supplierBankAccountGetAll()),
    onGetSuppliers: () => dispatch(actionsSupplier.supplierGetAll()),
    onSaveScheduledTask: (scheduledTask) => dispatch(actionsScheduledTask.scheduledTaskCreate(scheduledTask))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditScheduledTask);