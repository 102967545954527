import { numberFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';

export const id = {
	dataField: 'id',
	text: 'Id',
	filter: textFilter(),
	sort: true
};
export const firstName = {
	dataField: 'firstName',
	text: 'First name',
	filter: textFilter(),
	sort: true
};
export const lastName = {
	dataField: 'lastName',
	text: 'Last name',
	filter: textFilter(),
	sort: true
};
export const username = {
	dataField: 'username',
	text: 'Username',
	filter: textFilter(),
	sort: true
};
export const isActivated = {
	dataField: 'isActivated',
	text: 'Is activated',
	filter: textFilter(),
	sort: true
};
export const companies = {
	dataField: 'companies',
	text: 'Companies',
	filter: textFilter(),
	sort: true
};
export const companyInfo = {
	dataField: 'companyInfo',
	text: 'Companies',
	filter: textFilter(),
	sort: true
};
export const userRoleInfo = {
	dataField: 'userRoleInfo',
	text: 'Roles',
	filter: textFilter(),
	formatter: (cell, row) => formatUtils.formatUserRoleInfo(cell, row.isDisabled),
	sort: true
};
export const userAuthorizationInfo = {
	dataField: 'userAuthorizationInfo',
	text: 'Authorizations',
	filter: textFilter(),
	sort: true
};
