import * as actionTypes from "./actionTypes";

export const companySettingsCreate = (companySettings, history) => {
	return {
		type: actionTypes.COMPANY_SETTINGS_CREATE,
		companySettings: companySettings, 
		history: history
	}
}

export const companySettingsCreateError = (thisError) => {
	return {
		type: actionTypes.COMPANY_SETTINGS_CREATE_ERROR,
		error: thisError
	}
}

export const companySettingsCreateSuccess = () => {
	return {
		type: actionTypes.COMPANY_SETTINGS_CREATE_SUCCESS
	}
}

export const companySettingsDelete = (id, history) => {
	return {
		type: actionTypes.COMPANY_SETTINGS_DELETE,
		id: id,
		history: history
	}
}

export const companySettingsGet = () => {
	return {
		type: actionTypes.COMPANY_SETTINGS_GET
	}
}

export const companySettingsGetAll = () => {
	return {
		type: actionTypes.COMPANY_SETTINGS_GET_ALL
	}
}

export const companySettingsGetById = (id) => {
	return {
		type: actionTypes.COMPANY_SETTINGS_GET_BY_ID,
		id: id
	}
}

export const companySettingsGetError = (thisError) => {
	return {
		type: actionTypes.COMPANY_SETTINGS_GET_ERROR,
		error: thisError
	}
}

export const companySettingsGetSuccess = (data) => {
	return {
		type: actionTypes.COMPANY_SETTINGS_GET_SUCCESS,
		companySettings: data
	}
}

export const companySettingsUpdate_Invoicing = (updatedCompanySettings) => {
	return {
		type: actionTypes.COMPANY_SETTINGS_UPDATE_INVOICING,
		updatedCompanySettings: updatedCompanySettings
	}
}

export const companySettingsUpdate_Payments = (updatedCompanySettings) => {
	return {
		type: actionTypes.COMPANY_SETTINGS_UPDATE_PAYMENTS,
		updatedCompanySettings: updatedCompanySettings
	}
}

export const companySettingsUpdate_SupplierInvoiceProcessing = (updatedCompanySettings) => {
	return {
		type: actionTypes.COMPANY_SETTINGS_UPDATE_SUPPLIER_INVOICE_PROCESSING,
		updatedCompanySettings: updatedCompanySettings
	}
}

