import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsCompanySettings from "store/CompanySettings/actions";
import Breadcrumbs from "components/Common/Breadcrumb";
import * as chargeBearer from "definitions/enums/ChargeBearer";
import * as config from "../../config";
import * as editFormControls from "helpers/editFormControls";
import * as inputSelectUtils from "helpers/inputSelectUtils";
import { PaymentDateToUse } from "definitions/enums/PaymentDateToUse";

class CompanySettingsEdit_Payments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            paymentDetailsTemplateIntlPmt: "",
            paymentDetailsTemplateLocalPmt: "",
            paymentDateToUse: "",
            defaultChargeBearer: "",
            paymentsUsePurposeCodeFromInvoice: false,
            paymentsDefaultPurposeCode: "",
            paymentsAutoSetSHARToSEPA: "",
            paymentsConvertToAccountCurrency: "",
            paymentsDoGroup: "",
            paymentIdPrefix: "",
            paymentsEmailToNotifyOnFailedPayments: "",

            paymentsNotifyOnFailedPayments: false,

            changed: false,
            companySettings: [],
            error: null,
            warningText: "",
            warningLink: "",
            warningLinkText: ""
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            changed: true,
            [thisObjectName]: thisValue
        });
    }

    separateWithCommas = (value1, value2) => {
        if (value1 && value1.length && value2 && value2.length) {
            return value1 + "," + value2;
        } else if (value1 && value1.length) {
            return value1;
        } else {
            return value2;
        }
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        const invalidElements = document.querySelectorAll('.is-invalid');
        if (invalidElements && invalidElements.length) {
            invalidElements[0].focus();
        } else {
            const updatedCompanySettings = {
                paymentDetailsTemplateIntlPmt: this.state.paymentDetailsTemplateIntlPmt,
                paymentDetailsTemplateLocalPmt: this.state.paymentDetailsTemplateLocalPmt,
                paymentDateToUse: this.state.paymentDateToUse,
                defaultChargeBearer: this.state.defaultChargeBearer,
                paymentsUsePurposeCodeFromInvoice: this.state.paymentsUsePurposeCodeFromInvoice,
                paymentsDefaultPurposeCode: this.state.paymentsDefaultPurposeCode,
                paymentsAutoSetSHARToSEPA: this.state.paymentsAutoSetSHARToSEPA,
                paymentsConvertToAccountCurrency: this.state.paymentsConvertToAccountCurrency,
                paymentsDoGroup: this.state.paymentsDoGroup,
                paymentIdPrefix: this.state.paymentIdPrefix,
                paymentsEmailToNotifyOnFailedPayments: this.state.paymentsEmailToNotifyOnFailedPayments,
            };
            this.setState({
                changed: false
            }); // Should be before calling the Redux action to avoid the small delay between scrolling up and showing the alert
            this.props.onUpdateCompanySettings(updatedCompanySettings);
        }
    }

    componentDidMount() {
        this.props.onGetCompanySettings();
    }

    processCommaSeparatedList = (thisList) => {
        let result = "";
        if (thisList && thisList.length) {
            const listItems = thisList.split(",");
            for (let i in listItems) {
                let thisValue = listItems[i].trim();
                if (thisValue && thisValue.length) {
                    result = (result && result.length) ? result + "," + thisValue : thisValue;
                }
            }
        }
        return result;
    }

    addValueBeforeLastComma = (originalString, addedString) => {
        originalString = this.processCommaSeparatedList(originalString);
        addedString = addedString.trim();
        if (addedString && addedString.length) {
            if (originalString && originalString.length) {
                return (addedString && addedString.length && addedString.includes(",")) ? (originalString + "," + addedString.substring(0, addedString.lastIndexOf(","))) : originalString;
            } else {
                return (addedString && addedString.length && addedString.includes(",")) ? (addedString.substring(0, addedString.lastIndexOf(","))) : "";
            }
        } else {
            return originalString;
        }
    }

    valueAfterLastComma = (thisValue) => {
        return (thisValue && thisValue.length && thisValue.includes(",")) ? thisValue.substring(thisValue.lastIndexOf(",") + 1) : thisValue;
    }

    forwardToSettingsMandatory = () => {
        return (this.state.useGreylist)
            || (this.state.useWhitelist && !this.state.supplierInvoiceReceivingIgnoreSendersNotOnWhitelist);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.error !== this.props.error) {
            let error = this.props.error;
            this.setState({
                error: error
            });
        }

        // VAT rates, countries and currencies are checked in the reverse order to the priorities 
        // in which the warning messages about their absense should be displayed.
        // E.g. if neither currencies, countries or VAT rates are entered, the user should see the message that currencies are empty,
        // and only if some currencies are present, they should see the message that countries are empty, and so on.

        if (prevProps.companySettings !== this.props.companySettings) {
            if (this.props.companySettings) {
                this.setState({
                    paymentDetailsTemplateIntlPmt: this.props.companySettings.paymentDetailsTemplateIntlPmt,
                    paymentDetailsTemplateLocalPmt: this.props.companySettings.paymentDetailsTemplateLocalPmt,
                    paymentDateToUse: this.props.companySettings.paymentDateToUse,
                    defaultChargeBearer: this.props.companySettings.defaultChargeBearer,
                    paymentsUsePurposeCodeFromInvoice: this.props.companySettings.paymentsUsePurposeCodeFromInvoice,
                    paymentsDefaultPurposeCode: this.props.companySettings.paymentsDefaultPurposeCode,
                    paymentsAutoSetSHARToSEPA: this.props.companySettings.paymentsAutoSetSHARToSEPA,
                    paymentsConvertToAccountCurrency: this.props.companySettings.paymentsConvertToAccountCurrency,
                    paymentsDoGroup: this.props.companySettings.paymentsDoGroup,
                    paymentIdPrefix: this.props.companySettings.paymentIdPrefix,
                    paymentsEmailToNotifyOnFailedPayments: this.props.companySettings.paymentsEmailToNotifyOnFailedPayments,

                    paymentsNotifyOnFailedPayments: this.props.companySettings.paymentsEmailToNotifyOnFailedPayments && this.props.companySettings.paymentsEmailToNotifyOnFailedPayments.length,
                });
            }
        }


        if ((!prevProps.saveSuccess && this.props.saveSuccess) || (prevState.error !== this.state.error && this.state.error)) {
            window.scrollBy(0, -document.body.scrollHeight);
        }
    }

    removeItemFromCommaSeparatedString = (thisItem, thisString) => {
        if (thisString === thisItem) {
            return "";
        } else {
            thisString = ("," + thisString + ",").replaceAll("," + thisItem + ",", ",");
            return thisString.substring(1, thisString.length - 1);
        }
    }

    removeItemFromStateField = (thisItem, stateField) => {
        this.setState({
            [stateField]: this.removeItemFromCommaSeparatedString(thisItem, this.state[stateField])
        });
    }

    render() {
        const pageTitle = "Payment settings | " + config.AppName;
        const breadcrumbsTitle = "Company settings";
        const breadcrumbsItem = "Payments";

        const paymentDateOptions = inputSelectUtils.generateOptionsFromData(PaymentDateToUse, paymentDateRow => paymentDateRow.description);
        const chargeBearerOptions = inputSelectUtils.generateOptionsFromData(chargeBearer.ChargeBearer, chargeBearerRow => chargeBearerRow.description);

        const loading = this.props.loadingCompanySettings;

        const editForm = (
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >

                                {editFormControls.textAreaControl("paymentDetailsTemplateIntlPmt", "Payment details template (international payments)", this.onChange, this.state.paymentDetailsTemplateIntlPmt)}
                                {editFormControls.textAreaControl("paymentDetailsTemplateLocalPmt", "Payment details template (local payments)", this.onChange, this.state.paymentDetailsTemplateLocalPmt)}
                                {editFormControls.selectControl("paymentDateToUse", "Payment date to use", this.onChange, this.state.paymentDateToUse, paymentDateOptions)}
                                {editFormControls.selectControl("defaultChargeBearer", "Default charge bearer", this.onChange, this.state.defaultChargeBearer, chargeBearerOptions)}

                                {editFormControls.checkboxControl("paymentsUsePurposeCodeFromInvoice", "Use purpose code from invoice", this.onChange, this.state.paymentsUsePurposeCodeFromInvoice, "If checked, payment purpose code will be read from supplier invoice. Currently only available for supplier invoices read from an external system (e.g. an ERP). If you are not using CashManager with an external system or want to always use a specified payment purpose code, do not check it and fill in the next field 'Default payment purpose code'.")}
                                {!this.state.paymentsUsePurposeCodeFromInvoice && editFormControls.textControl("paymentsDefaultPurposeCode", "Default payment purpose code", this.onChange, this.state.paymentsDefaultPurposeCode)}

                                {editFormControls.checkboxControl("paymentsAutoSetSHARToSEPA", "Automatically set charge bearer to SHAR for SEPA payments", this.onChange, this.state.paymentsAutoSetSHARToSEPA)}
                                {editFormControls.checkboxControl("paymentsConvertToAccountCurrency", "Convert payments to account currency", this.onChange, this.state.paymentsConvertToAccountCurrency)}
                                {editFormControls.checkboxControl("paymentsDoGroup", "Group payments by default", this.onChange, this.state.paymentsDoGroup, "If checked, invoice payments to the same supplier, same bank account, same currency and same payment date will be grouped into a single payment. If not checked, a payment will be created for each supplier invoice.")}
                                {editFormControls.textControl("paymentIdPrefix", "Payment ID prefix", this.onChange, this.state.paymentIdPrefix, "", null, false, "An optional prefix of the MsgId field in ISO 20022 PAIN files. The rest of the value is a unique numeric ID generated by CashManager.")}

                                {editFormControls.checkboxControl("paymentsNotifyOnFailedPayments", "Notify on failed payments", this.onChange, this.state.paymentsNotifyOnFailedPayments, "Check to get notified by email in case payments made through Direct Channel fail. Make sure to set up a Bank File Receive scheduled task for automatic payment status update.")}
                                {this.state.paymentsNotifyOnFailedPayments && editFormControls.textControl("paymentsEmailToNotifyOnFailedPayments", "Notification email on failed payments", this.onChange, this.state.paymentsEmailToNotifyOnFailedPayments, "", null, false, "Email to receive notification in case of failed payments made through Direct Channel. In case of multiple emails, enter them separated by comma.")}

                                {editFormControls.saveButton(this.props.saving, true)}
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

                        {editFormControls.errorAlert(this.state.error)}

                        {editFormControls.warningAlertWithLink(this.state.warningText, this.state.warningLink, this.state.warningLinkText)}

                        {editFormControls.saveSuccessAlert(!this.state.changed && this.props.saveSuccess, "Settings")}

                        {editFormControls.formLoadingSpinner(loading)}

                        {!loading && editForm}

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ companySettings, userLogin }) => ({
    companySettings: companySettings.companySettings,
    error: companySettings.error,
    loadingCompanySettings: companySettings.loading,
    saveSuccess: companySettings.saveSuccess,
    saving: companySettings.saving,
    username: userLogin.userData.username
})

const mapDispatchToProps = dispatch => ({
    onGetCompanySettings: () => dispatch(actionsCompanySettings.companySettingsGet()),
    onUpdateCompanySettings: (companySettings) => dispatch(actionsCompanySettings.companySettingsUpdate_Payments(companySettings))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanySettingsEdit_Payments);
