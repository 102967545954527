import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";

import * as actionsCompanySettings from "store/CompanySettings/actions";
import * as actionsCustomerInvoice from '../../store/CustomerInvoice/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as config from "../../config";
import * as editFormControls from "helpers/editFormControls";

class CompanySettingsEdit_Invoicing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            invoicingSendPDF: false,
            invoicingSendPEPPOL: false,
            invoicingPDFTemplate: "",

            testDropdownOpen: false,
            changed: false,
            companySettings: [],
            error: null,
            warningText: "",
            warningLink: "",
            warningLinkText: ""
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            changed: true,
            [thisObjectName]: thisValue
        });
    }

    separateWithCommas = (value1, value2) => {
        if (value1 && value1.length && value2 && value2.length) {
            return value1 + "," + value2;
        } else if (value1 && value1.length) {
            return value1;
        } else {
            return value2;
        }
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        const invalidElements = document.querySelectorAll('.is-invalid');
        if (invalidElements && invalidElements.length) {
            invalidElements[0].focus();
        } else {
            const updatedCompanySettings = {
                invoicingSendPDF: this.state.invoicingSendPDF,
                invoicingSendPEPPOL: this.state.invoicingSendPEPPOL,
                invoicingPDFTemplate: this.state.invoicingPDFTemplate,
            };
            this.setState({
                changed: false
            }); // Should be before calling the Redux action to avoid the small delay between scrolling up and showing the alert
            this.props.onUpdateCompanySettings(updatedCompanySettings);
        }
    }

    componentDidMount() {
        this.props.onGetCompanySettings();
    }

    processCommaSeparatedList = (thisList) => {
        let result = "";
        if (thisList && thisList.length) {
            const listItems = thisList.split(",");
            for (let i in listItems) {
                let thisValue = listItems[i].trim();
                if (thisValue && thisValue.length) {
                    result = (result && result.length) ? result + "," + thisValue : thisValue;
                }
            }
        }
        return result;
    }

    addValueBeforeLastComma = (originalString, addedString) => {
        originalString = this.processCommaSeparatedList(originalString);
        addedString = addedString.trim();
        if (addedString && addedString.length) {
            if (originalString && originalString.length) {
                return (addedString && addedString.length && addedString.includes(",")) ? (originalString + "," + addedString.substring(0, addedString.lastIndexOf(","))) : originalString;
            } else {
                return (addedString && addedString.length && addedString.includes(",")) ? (addedString.substring(0, addedString.lastIndexOf(","))) : "";
            }
        } else {
            return originalString;
        }
    }

    valueAfterLastComma = (thisValue) => {
        return (thisValue && thisValue.length && thisValue.includes(",")) ? thisValue.substring(thisValue.lastIndexOf(",") + 1) : thisValue;
    }

    forwardToSettingsMandatory = () => {
        return (this.state.useGreylist)
            || (this.state.useWhitelist && !this.state.supplierInvoiceReceivingIgnoreSendersNotOnWhitelist);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.error !== this.props.error) {
            let error = this.props.error;
            this.setState({
                error: error
            });
        }

        // VAT rates, countries and currencies are checked in the reverse order to the priorities 
        // in which the warning messages about their absense should be displayed.
        // E.g. if neither currencies, countries or VAT rates are entered, the user should see the message that currencies are empty,
        // and only if some currencies are present, they should see the message that countries are empty, and so on.

        if (prevProps.companySettings !== this.props.companySettings) {
            if (this.props.companySettings) {
                this.setState({
                    invoicingSendPDF: this.props.companySettings.invoicingSendPDF,
                    invoicingSendPEPPOL: this.props.companySettings.invoicingSendPEPPOL,
                    invoicingPDFTemplate: this.props.companySettings.invoicingPDFTemplate,
                });
            }
        }


        if ((!prevProps.saveSuccess && this.props.saveSuccess) || (prevState.error !== this.state.error && this.state.error)) {
            window.scrollBy(0, -document.body.scrollHeight);
        }
    }

    removeItemFromCommaSeparatedString = (thisItem, thisString) => {
        if (thisString === thisItem) {
            return "";
        } else {
            thisString = ("," + thisString + ",").replaceAll("," + thisItem + ",", ",");
            return thisString.substring(1, thisString.length - 1);
        }
    }

    removeItemFromStateField = (thisItem, stateField) => {
        this.setState({
            [stateField]: this.removeItemFromCommaSeparatedString(thisItem, this.state[stateField])
        });
    }

    render() {
        const pageTitle = "Customer invoicing settings | " + config.AppName;
        const breadcrumbsTitle = "Company settings";
        const breadcrumbsItem = "Customer invoicing";

        const loading = this.props.loadingCompanySettings;

        const testButton =
            <Dropdown
                isOpen={this.state.testDropdownOpen}
                toggle={() => this.setState({
                    testDropdownOpen: !this.state.testDropdownOpen
                })}
            >
                <DropdownToggle
                    color="secondary"
                    disabled={this.state.changed || this.props.printing}
                    caret
                >
                    Test...
                    {" "}
                    {this.props.printing ? editFormControls.buttonSpinner() : null}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={this.props.onTestPrintCustomerInvoice}>Print to PDF</DropdownItem>
                    <DropdownItem onClick={this.props.onTestExportCustomerInvoiceToPeppol}>Export as PEPPOL</DropdownItem>
                </DropdownMenu>
            </Dropdown>


        const editForm = (
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                {editFormControls.checkboxControl("invoicingSendPDF", "Send invoices in PDF", this.onChange, this.state.invoicingSendPDF)}
                                {this.state.invoicingSendPDF && editFormControls.textAreaControl("invoicingPDFTemplate", "PDF template", this.onChange, this.state.invoicingPDFTemplate, "", "", !this.state.invoicingSendPDF)}
                                {editFormControls.checkboxControl("invoicingSendPEPPOL", "Send invoices in PEPPOL", this.onChange, this.state.invoicingSendPEPPOL)}

                                <div style={{ display: 'flex' }}>
                                    {editFormControls.saveButton(this.props.saving, true, !this.state.changed)}

                                    &nbsp;

                                    {testButton}
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

                        {editFormControls.errorAlert(this.state.error)}
                        {editFormControls.errorAlert(this.props.errorCustomerInvoice)}

                        {editFormControls.warningAlertWithLink(this.state.warningText, this.state.warningLink, this.state.warningLinkText)}

                        {editFormControls.saveSuccessAlert(!this.state.changed && this.props.saveSuccess, "Settings")}

                        {editFormControls.formLoadingSpinner(loading)}

                        {!loading && editForm}

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ companySettings, customerInvoice, userLogin }) => ({
    companySettings: companySettings.companySettings,
    error: companySettings.error,
    errorCustomerInvoice: customerInvoice.error,
    loadingCompanySettings: companySettings.loading,
    printing: customerInvoice.printing,
    saveSuccess: companySettings.saveSuccess,
    saving: companySettings.saving,
    username: userLogin.userData.username
})

const mapDispatchToProps = dispatch => ({
    onGetCompanySettings: () => dispatch(actionsCompanySettings.companySettingsGet()),
    onTestExportCustomerInvoiceToPeppol: () => dispatch(actionsCustomerInvoice.customerInvoiceTestExportToPeppol()),
    onTestPrintCustomerInvoice: () => dispatch(actionsCustomerInvoice.customerInvoiceTestPrintToPDF()),
    onUpdateCompanySettings: (companySettings) => dispatch(actionsCompanySettings.companySettingsUpdate_Invoicing(companySettings))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanySettingsEdit_Invoicing);
