import * as actionTypes from "./actionTypes";

const initialState = {
	companySettings: null,
	error: null,
	loading: false,
	saveSuccess: false,
	saving: false
}

export default function companySettingsReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.COMPANY_SETTINGS_GET:
			return {
				...state,
				error: null,
				loading: true,
				saveSuccess: false
			}
		case actionTypes.COMPANY_SETTINGS_GET_ALL:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.COMPANY_SETTINGS_GET_BY_ID:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.COMPANY_SETTINGS_GET_SUCCESS:
			return {
				...state,
				loading: false,
				companySettings: action.companySettings
			}
		case actionTypes.COMPANY_SETTINGS_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.COMPANY_SETTINGS_CREATE:
			return {
				...state,
				error: null,
				saveSuccess: false,
				saving: true
			}
		case actionTypes.COMPANY_SETTINGS_CREATE_ERROR:
			return {
				...state,
				error: action.error,
				saving: false
			}
		case actionTypes.COMPANY_SETTINGS_CREATE_SUCCESS:
			return {
				...state,
				error: null,
				saveSuccess: true,
				saving: false
			}
		case actionTypes.COMPANY_SETTINGS_UPDATE_INVOICING:
		case actionTypes.COMPANY_SETTINGS_UPDATE_PAYMENTS:
		case actionTypes.COMPANY_SETTINGS_UPDATE_SUPPLIER_INVOICE_PROCESSING:
			return {
				...state,
				error: null,
				saveSuccess: false,
				saving: true
			}
		default:
			return state;
	}
}