import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsUnit from '../../store/Unit/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import UNECERec20Codes from '../../definitions/UNECERec20Codes.json';

import classes from './Pages.module.css';
import { unECERec20Code } from "definitions/columns/unit";

class UnitEdit extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: "",
			nameLang0: "",
			nameLang1: "",
			externalId: "",
			unECERec20Code: "",
			units: [],

			changed: false,
			errors: {},
			showUNECERec20Codes: false
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue;
		if (e.target.type == "checkbox") {
			thisValue = e.target.checked;
		} else {
			thisValue = e.target.value;
		}
		const showUNECERec20Codes = (thisObjectName === "unECERec20Code");
		if (thisObjectName === "unECERec20Code") {
			thisValue = thisValue.toUpperCase();
		}
		this.setState({
			changed: true,
			[thisObjectName]: thisValue,
			showUNECERec20Codes: showUNECERec20Codes
		});
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
		let newOrUpdatedUnit = {
			nameLang0: this.state.nameLang0,
			nameLang1: this.state.nameLang1,
			externalId: this.state.externalId,
			unECERec20Code: this.state.unECERec20Code,
		}
		if (this.state.id) {
			newOrUpdatedUnit = {
				id: this.state.id,
				...newOrUpdatedUnit
			}
		}
		this.setState({
			changed: false
		}); // Should be before calling the Redux action to avoid the small delay between scrolling up and showing the alert
		this.props.onCreateUnit(newOrUpdatedUnit, this.props.history);
	}

	onDeleteUnit = () => {
		if (window.confirm("Are you sure you want to delete this Unit?")) {
			this.props.onDeleteUnit(this.state.id, this.props.history);
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.onGetUnitById(id);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.error !== this.props.error) {
			if (this.props.error) {
				this.setState({
					errors: this.props.error
				});
			} else {
				this.setState({
					errors: ""
				})
			}
		}

		if (prevProps.units !== this.props.units) {
			if (this.props.units && this.props.units[0]) {

				this.setState({
					id: this.props.units[0].id,
					nameLang0: this.props.units[0].nameLang0,
					nameLang1: this.props.units[0].nameLang1,
					externalId: this.props.units[0].externalId,
					unECERec20Code: this.props.units[0].unECERec20Code,
				});
			}
		}
	}

	setUNECERec20Code = (code) => {
		this.setState({
			unECERec20Code: code,
			showUNECERec20Codes: false
		});
	}

	hideUNECERec20Codes = () => {
		this.setState({
			showUNECERec20Codes: false
		});
	}

	highlightMatchingText = (text, highlight, color = "yellow") => {
		if (!highlight) {
			return <span>{text}</span>;
		}

		const regex = new RegExp(`(${highlight})`, "gi");
		const parts = text.split(regex);
		return (
			<span>
				{
					parts.map((part, index) =>
						part.toLowerCase() === highlight.toLowerCase() ? (
							<span key={index} style={{ backgroundColor: color }}>{part}</span>
						) : (
							part
						)
					)
				}
			</span >
		);
	};


	render() {
		const pageTitle = (this.state.id || this.props.loading ? "Edit" : "Create") + " Unit | " + config.AppName;
		const breadcrumbsTitle = "Unit";
		const breadcrumbsItem = (this.state.id || this.props.loading ? "Edit" : "New") + " Unit";

		const editForm = (
			<Row>
				<Col lg="12">
					<Card>
						<CardBody>
							<form
								className="outer-repeater"
								onSubmit={this.onSubmit}
							>
								<div data-repeater-list="outer-group" className="outer">
									<div data-repeater-item className="outer">

										{editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}

										{editFormControls.textControl("nameLang0", "Name", this.onChange, this.state.nameLang0)}
										{editFormControls.textControl("externalId", "External ID", this.onChange, this.state.externalId)}
										{editFormControls.textControl("unECERec20Code", "UN/ECE Rec.20 code", this.onChange, this.state.unECERec20Code, "Enter UN/ECE Rec.20 code", null, false, "Mandatory for PEPPOL e-invoices. For all codes and descriptions, see https://docs.peppol.eu/poacc/billing/3.0/2024-Q2/codelist/UNECERec20/")}

										{this.state.showUNECERec20Codes &&
											(<React.Fragment>
												<div
													className={classes.Background}
													onClick={this.hideUNECERec20Codes}
												/>
												<Row>
													<Col lg={editFormControls.labelColSize} />
													<Col lg={editFormControls.valueColSize}>
														<div className={classes.UNECERec20CodeSelectionContainer}>
															<Card style={{ height: "100%" }}>
																<CardBody style={{ height: "100%" }}>
																	<div className={classes.UNECERec20CodeSelectionList}>
																		<table className={classes.UNECERec20CodeSelectionTable}>
																			{UNECERec20Codes
																				.filter(codeRow =>
																					codeRow.code.toLowerCase().includes(this.state.unECERec20Code.toLowerCase())
																					|| codeRow.description.toLowerCase().includes(this.state.unECERec20Code.toLowerCase())
																				).sort((codeRow1, codeRow2) => {
																					if (codeRow1.code === this.state.unECERec20Code) {
																						return -1;
																					} else if (codeRow2.code === this.state.unECERec20Code) {
																						return 1;
																					} else if (codeRow1.code.startsWith(this.state.unECERec20Code)) {
																						return -1;
																					} else if (codeRow2.code.startsWith(this.state.unECERec20Code)) {
																						return 1;
																					} else if (codeRow1.description.toUpperCase() == this.state.unECERec20Code) {
																						return -1;
																					} else if (codeRow2.description.toUpperCase() == this.state.unECERec20Code) {
																						return 1;
																					} else if (codeRow1.description.toUpperCase().startsWith(this.state.unECERec20Code)) {
																						return -1;
																					} else if (codeRow2.description.toUpperCase().startsWith(this.state.unECERec20Code)) {
																						return 1;
																					} else {
																						return codeRow1.code < codeRow2.code
																							? -1
																							: codeRow1.code > codeRow2.code
																								? 1
																								: 0;
																					}
																				})
																				.map(codeRow =>
																					<tr
																						onClick={() => this.setUNECERec20Code(codeRow.code)}
																					>
																						<td>{this.highlightMatchingText(codeRow.code, this.state.unECERec20Code)}</td>
																						<td>{this.highlightMatchingText(codeRow.description, this.state.unECERec20Code)}</td>
																					</tr>
																				)
																			}
																		</table>
																	</div>
																</CardBody>
															</Card>
														</div>
													</Col>
												</Row>
											</React.Fragment>)
										}

									</div>
								</div>
								<Row className="justify-content-end">
									<Col lg="10">
										{editFormControls.saveButton(this.props.saving, this.state.id)}
										<span> </span>
										{editFormControls.deleteButton(this.props.deleting, this.onDeleteUnit, this.state.id)}
									</Col>
								</Row>
							</form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						{editFormControls.errorAlert(this.props.error)}

						{editFormControls.saveSuccessAlert(!this.state.changed && this.props.saveSuccess, "Unit", "Browse units", endpointsFrontend.UNIT_BROWSE)}

						{editFormControls.formLoadingSpinner(this.props.loading)}

						{!this.props.loading ? editForm : null}

					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ unit }) => ({
	error: unit.error,
	deleting: unit.deleting,
	loading: unit.loading,
	saveSuccess: unit.saveSuccess,
	saving: unit.saving,
	units: unit.units
})

const mapDispatchToProps = dispatch => ({
	onGetUnitById: (id) => dispatch(actionsUnit.unitGetById(id)),
	onCreateUnit: (unit, history) => dispatch(actionsUnit.unitCreate(unit, history)),
	onDeleteUnit: (id, history) => dispatch(actionsUnit.unitDelete(id, history))
})


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UnitEdit);
