import { numberFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';

export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const code = {
	dataField: 'code',
	text: 'Code',
	filter: textFilter(),
	sort: true
};
export const nameLang0 = {
	dataField: 'nameLang0',
	text: 'Name',
	filter: textFilter(),
	sort: true
};
export const nameLang1 = {
	dataField: 'nameLang1',
	text: 'Name (language #2)',
	filter: textFilter(),
	sort: true
};
export const externalId = {
	dataField: 'externalId',
	text: 'External ID',
	filter: textFilter(),
	sort: true
};
export const unECERec20Code = {
	dataField: 'unECERec20Code',
	text: 'UN/ECE Rec.20 code',
	filter: textFilter(),
	sort: true
};
