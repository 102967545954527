import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { connect } from "react-redux";

import * as actions from '../../store/AllocationCriterion/actions';
import * as actionsCustomer from '../../store/Customer/actions';
import * as actionsSupplier from '../../store/Supplier/actions';
import * as AllocationCriterionTrustLevel from '../../definitions/enums/AllocationCriterionTrustLevel';
import Backdrop from '../Common/Backdrop';
import * as editFormControls from '../../helpers/editFormControls';
import * as formatUtils from '../../helpers/formatUtils';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';

import classes from './Pages.module.css';

const fieldDefaultValues = {
    id: 0,
    sequenceNo: 0,
    trustLevel: "SUGGESTION",
    type: "",
    hasAmount: true,
    hasAmountSum: true,
    hasPaymentDiscounts: false,
    hasBeneficiaryName: false,
    hasBeneficiaryRegNo: false,
    hasCurrency: true,
    hasInvoiceNoFullyContainedInPaymentDetails: false,
    hasPaymentDetailsFullyMatch: false,
    hasRecordTypeIsBankCharge: false,
    beneficiaryId: 0,
    paymentDetailsTemplate: "",

    amountDetection: 1,
    invoiceNoAltDetection: 0,
    beneficiaryDetection: 0,
    paymentDateDetection: 0,
    paymentDetailsDetection: 0,
}

class EditAllocationCriterion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...fieldDefaultValues,

            changed: false
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            [thisObjectName]: thisValue
        });
        if (thisObjectName === "beneficiaryDetection" && thisValue == 2) {
            if (this.state.type === "SUPPLIER_INVOICE") {
                if (this.props.suppliers && this.props.suppliers.length) {
                    this.setState({
                        beneficiaryId: this.props.suppliers[0].id
                    });
                }
            } else if (this.state.type === "CUSTOMER_INVOICE") {
                if (this.props.customers && this.props.customers.length) {
                    this.setState({
                        beneficiaryId: this.props.customers[0].id
                    });
                }
            }
        }
    }

    setStateFromAllocationCriterion() {
        if (this.props.allocationCriterion && this.props.allocationCriterion.id) {
            this.setState({
                id: this.props.allocationCriterion.id,
                hasAmount: this.props.allocationCriterion.hasAmount,
                hasAmountSum: this.props.allocationCriterion.hasAmountSum,
                hasPaymentDiscounts: this.props.allocationCriterion.hasPaymentDiscounts,
                hasCurrency: this.props.allocationCriterion.hasCurrency,
                type: this.props.allocationCriterion.type,
                sequenceNo: this.props.allocationCriterion.sequenceNo,
                trustLevel: this.props.allocationCriterion.trustLevel,
                hasBeneficiaryName: this.props.allocationCriterion.hasBeneficiaryName,
                hasBeneficiaryRegNo: this.props.allocationCriterion.hasBeneficiaryRegNo,
                hasRecordTypeIsBankCharge: this.props.allocationCriterion.hasRecordTypeIsBankCharge,
                hasInvoiceNoFullyContainedInPaymentDetails: this.props.allocationCriterion.hasInvoiceNoFullyContainedInPaymentDetails,
                hasPaymentDetailsFullyMatch: this.props.allocationCriterion.hasPaymentDetailsFullyMatch,
                beneficiaryId: this.props.allocationCriterion.beneficiaryId,
                paymentDetailsTemplate: this.props.allocationCriterion.paymentDetailsTemplate,

                amountDetection: this.props.allocationCriterion.hasAmount
                    ? 1
                    : this.props.allocationCriterion.hasAmountSum
                        ? 2
                        : 0,
                invoiceNoAltDetection: this.props.allocationCriterion.hasInvoiceNoAltFullyContainedInPaymentDetails
                    ? 1
                    : this.props.allocationCriterion.hasInvoiceNoAltNumericPartInPaymentDetails
                        ? 2
                        : 0,
                beneficiaryDetection: this.props.allocationCriterion.hasBeneficiaryName || this.props.allocationCriterion.hasBeneficiaryRegNo
                    ? 1
                    : this.props.allocationCriterion.beneficiaryId
                        ? 2
                        : 0,
                paymentDateDetection: this.props.allocationCriterion.hasPaymentDateGreaterThanInvoiceDate
                    ? 1
                    : this.props.allocationCriterion.hasPaymentDateGreaterThanOrEqualsInvoiceDate
                        ? 2
                        : this.props.allocationCriterion.hasPaymentDateEqualsInvoiceDate
                            ? 3
                            : 0,
                paymentDetailsDetection: this.props.allocationCriterion.paymentDetailsTemplate && this.props.allocationCriterion.paymentDetailsTemplate.length ? 1 : 0,
            });
        } else {
            this.setState({
                ...fieldDefaultValues
            });
            this.setState({
                type: this.props.allocationCriterion.type ? this.props.allocationCriterion.type : "",
            });
            if (this.props.allocationCriterion.type === "BANK_CHARGE") {
                this.setState({
                    hasAmount: false,
                    hasCurrency: false
                });
            }
        }
    }

    componentDidMount() {
        this.setStateFromAllocationCriterion();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.allocationCriterion !== this.props.allocationCriterion) {
            this.setStateFromAllocationCriterion();
        }

        if (prevState.beneficiaryDetection != 2 && this.state.beneficiaryDetection == 2) {
            switch (this.state.type) {
                case "PAYMENT":
                case "SUPPLIER_INVOICE":
                    if (!this.props.suppliers || !this.props.suppliers.length) {
                        this.props.onGetSuppliers();
                    }
                    break;
                case "CUSTOMER_INVOICE":
                    if (!this.props.customers || !this.props.customers.length) {
                        this.props.onGetCustomers();
                    }
                    break;
            }
        }

        if (prevProps.customers !== this.props.customers && this.props.customers && this.props.customers.length) {
            this.setState({
                beneficiaryId: this.props.customers[0].id
            })
        }

        if (prevProps.suppliers !== this.props.suppliers && this.props.suppliers && this.props.suppliers.length) {
            this.setState({
                beneficiaryId: this.props.suppliers[0].id
            })
        }
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        let newOrUpdatedAllocationCriterion = {
            hasAmount: (this.state.type === "CUSTOMER_INVOICE" || this.state.type === "SUPPLIER_INVOICE") ? this.state.amountDetection == 1 : this.state.hasAmount,
            hasAmountSum: this.state.amountDetection == 2,
            hasPaymentDiscounts: this.state.hasPaymentDiscounts,
            hasCurrency: this.state.hasCurrency,
            type: this.state.type,
            sequenceNo: this.state.sequenceNo,
            trustLevel: this.state.trustLevel,
            hasBeneficiaryName: (this.state.type === "PAYMENT" || this.state.beneficiaryDetection == 1) && this.state.hasBeneficiaryName,
            hasBeneficiaryRegNo: (this.state.type === "PAYMENT" || this.state.beneficiaryDetection == 1) && this.state.hasBeneficiaryRegNo,
            hasRecordTypeIsBankCharge: this.state.hasRecordTypeIsBankCharge,
            hasInvoiceNoFullyContainedInPaymentDetails: this.state.hasInvoiceNoFullyContainedInPaymentDetails,
            hasInvoiceNoAltFullyContainedInPaymentDetails: this.state.invoiceNoAltDetection == 1,
            hasInvoiceNoAltNumericPartInPaymentDetails: this.state.invoiceNoAltDetection == 2,
            hasPaymentDetailsFullyMatch: this.state.hasPaymentDetailsFullyMatch,
            hasPaymentDateGreaterThanInvoiceDate: this.state.paymentDateDetection == 1,
            hasPaymentDateGreaterThanOrEqualsInvoiceDate: this.state.paymentDateDetection == 2,
            hasPaymentDateEqualsInvoiceDate: this.state.paymentDateDetection == 3,
            beneficiaryId: this.state.beneficiaryDetection == 2 ? this.state.beneficiaryId : null,
            paymentDetailsTemplate: this.state.paymentDetailsDetection == 1 ? this.state.paymentDetailsTemplate : "",
        };
        if (this.state.id) {
            newOrUpdatedAllocationCriterion = {
                id: this.state.id,
                ...newOrUpdatedAllocationCriterion
            };
        }
        this.props.onSaveAllocationCriterion(newOrUpdatedAllocationCriterion);
        this.setState({
            changed: false
        });
        this.props.onClose();
    }

    closeForm = () => {
        this.props.onClose();
    }

    customControlWithLabel = (props) => (
        <FormGroup className="mb-1" row>
            <Label
                htmlFor={props.fieldName}
                className={"col-form-label col-lg-3"}
            >
                {props.fieldTitle}
                {props.hintText && props.hintText.length ? editFormControls.hintComponent(props.fieldName, props.hintText) : null}
            </Label>
            <Col lg="6">
                {props.children}
            </Col>
        </FormGroup>
    );

    customCheckboxControl(fieldName, fieldTitle, onChange, stateField, disabled, hintText) {
        return (
            <this.customControlWithLabel
                fieldName={fieldName}
                fieldTitle={fieldTitle}
                hintText={hintText}
            >
                {editFormControls.checkboxControlWithoutLabel(fieldName, onChange, stateField, disabled)}
            </this.customControlWithLabel>
        );
    }

    customStaticTextControl = (fieldName, fieldTitle, value) => {
        return (
            <this.customControlWithLabel
                fieldName={fieldName}
                fieldTitle={fieldTitle}
            >
                {editFormControls.staticTextControlWithoutLabel(fieldName, value)}
            </this.customControlWithLabel>
        );
    }

    customSelectControl(fieldName, fieldTitle, onChange, stateField, options, disabled, isInvalid) {
        return (
            <this.customControlWithLabel
                fieldName={fieldName}
                fieldTitle={fieldTitle}
            >
                {editFormControls.selectControlWithoutLabel(fieldName, onChange, stateField, options, disabled, isInvalid)}
            </this.customControlWithLabel>
        );
    }

    customRadioButton(fieldName, stateField, value, caption) {
        return (
            <React.Fragment>
                <Row style={{ paddingBottom: "0.4rem" }}>
                    <Col xs="auto">
                        <Input
                            name={fieldName}
                            type="radio"
                            onChange={this.onChange}
                            checked={stateField == value}
                            value={value}
                        />
                    </Col>
                    <Col>
                        {caption}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    controlsUnderRadioOption(props) {
        return (
            <React.Fragment>
                <Row style={{ paddingBottom: "0.8rem" }}>
                    <Col xs="auto">
                    </Col>
                    <Col xs="auto">
                    </Col>
                    <Col>
                        <Row>
                            {props.children}
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    amountControls() {
        return (
            <React.Fragment>
                <this.customControlWithLabel
                    fieldName="amountDetection"
                    fieldTitle="Amount"
                >
                    <br />
                    {this.customRadioButton("amountDetection", this.state.amountDetection, 0, "No matching")}
                    {this.customRadioButton("amountDetection", this.state.amountDetection, 1, "One-by-one matching")}
                    {this.customRadioButton("amountDetection", this.state.amountDetection, 2, "Sum matching")}
                </this.customControlWithLabel>
            </React.Fragment>
        )
    }

    invoiceNoAltControls() {
        return (
            <React.Fragment>
                <this.customControlWithLabel
                    fieldName="invoiceNoAltDetection" 
                    fieldTitle="Alt. invoice No."
                >
                    <br />
                    {this.customRadioButton("invoiceNoAltDetection", this.state.invoiceNoAltDetection, 0, "Any")}
                    {this.customRadioButton("invoiceNoAltDetection", this.state.invoiceNoAltDetection, 1, "Exact match")}
                    {this.customRadioButton("invoiceNoAltDetection", this.state.invoiceNoAltDetection, 2, "Numeric part match")}
                </this.customControlWithLabel>
            </React.Fragment>
        )
    }

    beneficiaryDetectionControls() {
        const beneficiaryCaption = formatUtils.getBeneficiaryCaption(this.state.type);

        let loading = false;
        let beneficiaryDataArray = [];
        switch (this.state.type) {
            case "PAYMENT":
            case "SUPPLIER_INVOICE":
                beneficiaryDataArray = this.props.suppliers;
                loading = this.props.loadingSuppliers;
                break;
            case "CUSTOMER_INVOICE":
                beneficiaryDataArray = this.props.customers;
                loading = this.props.loadingCustomers;
                break;
        }

        const beneficiaryOptions = inputSelectUtils.generateOptionsFromData(
            beneficiaryDataArray,
            row => (row.name + (row.externalId && row.externalId.length ? " (" + row.externalId + ")" : "")),
            (row1, row2) => (row1.name > row2.name ? 1 : row1.name === row2.name ? 0 : -1)
        );

        return (
            <React.Fragment>
                <this.customControlWithLabel
                    fieldName="beneficiaryDetection"
                    fieldTitle={beneficiaryCaption}
                >
                    <br />
                    {this.customRadioButton("beneficiaryDetection", this.state.beneficiaryDetection, 0, "Any")}
                    {this.customRadioButton("beneficiaryDetection", this.state.beneficiaryDetection, 1, "Detected by structured fields")}
                    <this.controlsUnderRadioOption>
                        <Row>
                            <Col xs="auto" style={{ paddingLeft: "0px" }}>
                                {editFormControls.checkboxControlWithoutLabel("hasBeneficiaryName", this.onChange, this.state.hasBeneficiaryName, this.state.beneficiaryDetection != 1)}
                            </Col>
                            <Col style={{ paddingLeft: "0px" }}>
                                {beneficiaryCaption} name
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs="auto" style={{ paddingLeft: "0px" }}>
                                {editFormControls.checkboxControlWithoutLabel("hasBeneficiaryRegNo", this.onChange, this.state.hasBeneficiaryRegNo, this.state.beneficiaryDetection != 1)}
                            </Col>
                            <Col style={{ paddingLeft: "0px" }}>
                                {beneficiaryCaption} reg. No.
                            </Col>
                        </Row>
                    </this.controlsUnderRadioOption>
                    {this.customRadioButton("beneficiaryDetection", this.state.beneficiaryDetection, 2, "Equals to:")}
                    <this.controlsUnderRadioOption>
                        {loading ? editFormControls.bigSpinner() : editFormControls.selectControlWithoutLabel("beneficiaryId", this.onChange, this.state.beneficiaryId, beneficiaryOptions, this.state.beneficiaryDetection != 2)}
                    </this.controlsUnderRadioOption>
                </this.customControlWithLabel>
            </React.Fragment>
        )
    }

    paymentDateControls() {
        return (
            <React.Fragment>
                <this.customControlWithLabel
                    fieldName="paymentDateDetection"
                    fieldTitle="Payment date"
                >
                    <br />
                    {this.customRadioButton("paymentDateDetection", this.state.paymentDateDetection, 0, "Any")}
                    {this.customRadioButton("paymentDateDetection", this.state.paymentDateDetection, 1, "Greater than invoice date")}
                    {this.customRadioButton("paymentDateDetection", this.state.paymentDateDetection, 2, "Greater than or equals invoice date")}
                    {this.customRadioButton("paymentDateDetection", this.state.paymentDateDetection, 3, "Equals invoice date")}
                </this.customControlWithLabel>
            </React.Fragment>
        )
    }

    paymentDetailsControls() {
        return (
            <React.Fragment>
                <this.customControlWithLabel
                    fieldName="paymentDetailsDetection"
                    fieldTitle="Payment details"
                >
                    <br />
                    {this.customRadioButton("paymentDetailsDetection", this.state.paymentDetailsDetection, 0, "Any")}
                    {this.customRadioButton("paymentDetailsDetection", this.state.paymentDetailsDetection, 1, "Matches the template:")}
                    <this.controlsUnderRadioOption>
                        <Row>
                            <Col style={{ paddingLeft: "0px" }}>
                                {editFormControls.textControlWithoutLabel("paymentDetailsTemplate", this.onChange, this.state.paymentDetailsTemplate, "Enter payment details template", false, this.state.paymentDetailsDetection != 1)}
                            </Col>
                        </Row>
                    </this.controlsUnderRadioOption>
                </this.customControlWithLabel>
            </React.Fragment>
        )
    }

    render() {

        let criterionFieldControls;
        switch (this.state.type) {
            case "PAYMENT":
                criterionFieldControls = <React.Fragment>
                    {this.customCheckboxControl("hasAmount", "Amount", this.onChange, this.state.hasAmount)}
                    {this.customCheckboxControl("hasCurrency", "Currency", this.onChange, this.state.hasCurrency)}
                    {this.customCheckboxControl("hasBeneficiaryName", "Beneficiary name", this.onChange, this.state.hasBeneficiaryName)}
                    {this.customCheckboxControl("hasBeneficiaryRegNo", "Beneficiary reg. No.", this.onChange, this.state.hasBeneficiaryRegNo)}
                    {this.customCheckboxControl("hasPaymentDetailsFullyMatch", "Payment details (full match)", this.onChange, this.state.hasPaymentDetailsFullyMatch)}
                </React.Fragment>
                break;
            case "CUSTOMER_INVOICE":
                criterionFieldControls = <React.Fragment>
                    {this.amountControls()}
                    {this.customCheckboxControl("hasPaymentDiscounts", "Payment discounts", this.onChange, this.state.hasPaymentDiscounts, this.state.amountDetection == 0)}
                    {this.customCheckboxControl("hasCurrency", "Currency", this.onChange, this.state.hasCurrency)}
                    {this.customCheckboxControl("hasInvoiceNoFullyContainedInPaymentDetails", "Invoice No. (full match)", this.onChange, this.state.hasInvoiceNoFullyContainedInPaymentDetails)}
                    {this.invoiceNoAltControls()}
                    {this.beneficiaryDetectionControls()}
                    {this.paymentDateControls()}
                    {this.paymentDetailsControls()}
                </React.Fragment>
                break;
            case "SUPPLIER_INVOICE":
                criterionFieldControls = <React.Fragment>
                    {this.amountControls()}
                    {this.customCheckboxControl("hasCurrency", "Currency", this.onChange, this.state.hasCurrency)}
                    {this.customCheckboxControl("hasInvoiceNoFullyContainedInPaymentDetails", "Invoice No. (full match)", this.onChange, this.state.hasInvoiceNoFullyContainedInPaymentDetails)}
                    {this.invoiceNoAltControls()}
                    {this.beneficiaryDetectionControls()}
                    {this.paymentDateControls()}
                    {this.paymentDetailsControls()}
                </React.Fragment>
                break;
            case "BANK_CHARGE":
                criterionFieldControls = <React.Fragment>
                    {this.customCheckboxControl("hasRecordTypeIsBankCharge", "Record type is 'Bank charge'", this.onChange, this.state.hasRecordTypeIsBankCharge)}
                </React.Fragment>
                break;
        }

        const AllocationCriterionTrustLevelOptions = inputSelectUtils.generateOptionsFromData(
            AllocationCriterionTrustLevel.AllocationCriterionTrustLevel.filter(row => row.id !== 'BUILT_IN'),
            row => row.description
        );

        return (

            <div>
                <Backdrop
                    show
                    onClick={this.closeForm}
                >
                </Backdrop>
                <div className={classes.EditAllocationCriterionContainer}>
                    <Card className={classes.PageCard} style={{ height: "100%" }}>  {/* Setting height of PageCard in css is ignored */}
                        <CardTitle>
                            <div className={classes.CardTitleDiv}>
                                <div className={classes.CardTitleSubDiv}>
                                    {this.props.allocationCriterion ? "Edit" : "Create"} allocation criterion
                                </div>
                                <div className={classes.CloseButtonDiv}>
                                    <p className={classes.CloseButtonDivP}>
                                        <i
                                            className="bx bx-x"
                                            onClick={this.closeForm}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </p>
                                </div>
                            </div>
                            <br />
                        </CardTitle>
                        <CardBody className={classes.EditAllocationCriterionCardBody}>

                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                {editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
                                {editFormControls.hiddenValueControl("type", this.onChange, this.state.type)}

                                {this.customStaticTextControl("sequenceNo", "Sequence No.", this.state.sequenceNo)}
                                {this.customSelectControl("trustLevel", "Trust level", this.onChange, this.state.trustLevel, AllocationCriterionTrustLevelOptions)}

                                {criterionFieldControls}
                                <Row>
                                    <Col style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center"
                                    }}>
                                        {editFormControls.saveButton(this.props.saving, this.state.id)}
                                    </Col>
                                </Row>
                            </form>

                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ allocationCriterion, customer, supplier }) => ({
    customers: customer.customers,
    loadingCustomers: customer.loading,
    loadingSuppliers: supplier.loading,
    saving: allocationCriterion.saving,
    suppliers: supplier.suppliers
});

const mapDispatchToProps = dispatch => ({
    onGetCustomers: () => dispatch(actionsCustomer.customerGetAll()),
    onSaveAllocationCriterion: (allocationCriterion) => dispatch(actions.allocationCriterionCreate(allocationCriterion)),
    onGetSuppliers: () => dispatch(actionsSupplier.supplierGetAll())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditAllocationCriterion);